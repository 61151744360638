.url {
  &__input {
    margin-top: 15px;
  }

  &__test {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
