html {
  background: #eeeeee;
}

body {
  margin: 0;
  padding: 0;
  background: #eeeeee;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
