.prepopulate-assistant {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 99999;

  transform: translate(-50%, -100%);
  transition: all 0.3s ease-in-out;

  &--visible {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }

  background: #0b91ff;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.375rem 0.375rem;
  color: #ffffff;
  font-size: 12px;

  &__count {
    font-weight: 700;
  }

  &__copy {
    margin-left: 30px;
  }

  &__close {
    margin-left: 15px;
    color: #ffffff !important;
  }
}
