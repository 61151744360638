@use 'sass:color';

.step-card {
  margin-bottom: 30px;
  position: relative;
  padding-left: 45px;

  @media screen and (min-width: 576px) {
    transform: translateX(-15px);
    transition: transform 0.2s ease-in-out;

    &--valid {
      transform: translateX(15px);
    }
  }

  .card-body {
    @media screen and (max-width: 767px) {
      padding: 15px !important;
    }
  }

  .card-title {
    margin-bottom: 20px !important;
  }

  &__icon {
    width: 32px;
    height: 32px;
    position: absolute;
    display: block;
    left: 0;
    top: 25px;
    transition: all 0.2s ease-in-out;
    transform: scale(0.01);
    opacity: 0;
    visibility: hidden;

    &--visible {
      transition-delay: 0.2s;
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }

  &__card {
    border: 2px #ffb400 solid !important;
    box-shadow: none !important;

    &--valid {
      border-color: #17c671 !important;
    }
  }
}
