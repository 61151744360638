.result {
  &__url {
    margin-bottom: 5px;
  }

  &__prepopulate {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 11px;
    color: #999999;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #000000;
    }
  }
}
