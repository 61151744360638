.prepopulate-item {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 0.375rem;
    transition: all 0.2s ease-in-out;
  }

  &--selectable,
  &--unselectable {
    cursor: pointer;

    &::before {
      opacity: 0.25;
      visibility: visible;
    }
  }

  &--selectable::before {
    background: #0b91ff;
  }

  &--unselectable {
    cursor: not-allowed;

    &::before {
      background: #d14d00;
    }
  }

  &--selectable:hover {
    &::before {
      opacity: 0.5;
    }
  }

  &--selected {
    &::before {
      background: #00d134;
      opacity: 0.7;
    }
  }

  &--selectable &__control {
    pointer-events: none;
  }
}
